.hamburger_menu_container {
	display: none;
}

.hamburger_menu_open {
	display: none;
}

.hamburger_menu_item {
	display: none;
}

@media (max-width: 767px) {
	.hamburger_menu_container {
		display: grid;
		position: absolute;
		justify-content: center;
		justify-items: center;
		margin: auto;
		top: 0;
		left: 0;

		z-index: 10;
		background: var(--color-primary-100);
		transition: all 1s ease;
	}

	.hamburger_menu_closed {
		height: 0;
		width: 0;
		transition: all 1s ease;
	}
	.hamburger_menu_open {
		display: flex;
		position: relative;
		flex-direction: column;
		align-items: center;
		top: 25vh;
		width: 100vw;
		height: 100vh;
		transition: all 1s ease;
	}

	.hamburger_menu_item {
		display: block;
		color: var(--color-primary-100);
		background: var(--color-primary-900);
		margin: var(--spacing-small-px);
		padding: var(--spacing-xsmall-px);
		width: 150px;
		text-align: center;
		border-radius: 5px;
	}
}
