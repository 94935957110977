.navbutton_container {
	display: grid;
	align-items: center;
	margin-right: 15px;
	margin-left: 15px;
	height: 50px;
	font-family: var(--font-mono);
	color: var(--color-primary-900);
	letter-spacing: 1px;
}
