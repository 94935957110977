.navbar_container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.navbar_items {
	display: flex;
	position: relative;
	width: 100%;
}

.navbutton_wrapper {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}
