.icon_container {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 70px;
	height: 70px;
	color: var(--color-primary-100);
	cursor: pointer;
}

.icon_container:hover {
	filter: scale(1.2);
}

.hamburger {
	width: 30px;
	height: 4px;
	max-height: 4px;
	background-color: var(--color-primary-100);
	margin: 3px 10px;
	transition: all 500ms;
}
.hamburger:hover {
	filter: brightness(1.2);
}

.x_icon {
	display: grid;
	align-items: center;
	justify-content: center;
	color: var(--color-primary-900);
	background: var(--color-accent);
	border-radius: 50%;
	height: 40px;
	width: 40px;
	box-shadow: 3px 2px 3px rgba(0, 0, 0, 0.3), inset 2px 2px 2px rgba(255, 255, 255, 0.6),
		inset -2px -2px 2px rgba(0, 0, 0, 0.3);
	transition: all 300ms;
}

@media (min-width: 768px) {
	.icon_container {
		display: none;
	}
}
